import React, { useState } from 'react';
import { Box, Flex, Text, Button, Spacer } from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import EthPrice from './EthPrice';

const PriceSelector = ({ onUpdate }) => {
  const [amount, setAmount] = useState(1);
  const pricePerUnit = 0.0009; // Define the price per unit in ETH

  // Increment the amount
  const incrementAmount = () => {
    setAmount(prev => {
      const newAmount = prev + 1;
      onUpdate(newAmount, newAmount * pricePerUnit);
      return newAmount;
    });
  };

  // Decrement the amount, ensuring it doesn't go below 1
  const decrementAmount = () => {
    setAmount(prev => {
      const newAmount = prev > 1 ? prev - 1 : 1;
      onUpdate(newAmount, newAmount * pricePerUnit);
      return newAmount;
    });

  };

  const dontBe = () => {
    alert('Dont be a Greedy Degen! One mint at a time, please');
  }

  return (
    <Flex align="center" p="4" bg="gray.200" w="full">
      <Text>Price: {(pricePerUnit * amount).toFixed(4)} {' '}ETH</Text>
       <EthPrice amount={pricePerUnit}/> 
      <Spacer />
      <Flex align="center">
        <Button onClick={decrementAmount} size="sm" px="2">
          <FiArrowLeft />
        </Button>
        <Text mx="2">{amount}</Text>
        <Button onClick={dontBe} size="sm" px="2" disabled={true}>
          <FiArrowRight />
        </Button>
      </Flex>
    </Flex>
  );
};

export default PriceSelector;
