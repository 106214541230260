import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import Web3 from 'web3';
import ConnectWalletWarning from './ConnectWalletWarning';



const Navbar = () => {
  const { isOpen: isLearnOpen, onOpen: onLearnOpen, onClose: onLearnClose } = useDisclosure();
  const { isOpen: isAboutOpen, onOpen: onAboutOpen, onClose: onAboutClose } = useDisclosure();
  const { isOpen: isEcosystemOpen, onOpen: onEcosystemOpen, onClose: onEcosystemClose } = useDisclosure();
  const { isOpen: isGenerator, onOpen: onGeneratorOpen, onClose: onGeneratorClose } = useDisclosure();

  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [balance, setBalance] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);


  useEffect(() => {
    checkWalletConnection();
    checkNetwork();
  }, []);

  const checkWalletConnection = async () => {
    const provider = window.ethereum;
    if (provider) {
      const accounts = await provider.request({ method: 'eth_accounts' });
      setIsConnected(accounts.length > 0);
    }
  };

  const checkNetwork = async () => {
    const provider = window.ethereum;
    if (provider) {
      const chainId = await provider.request({ method: 'eth_chainId' });
      setIsCorrectNetwork(chainId === '0x2105'); // Change to the correct chainId for the Base network
    }
  };


   // Function to handle connecting to MetaMask
   const connectWalletHandler = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        setWeb3(web3);
        setAccounts(accounts);
        const balance = await web3.eth.getBalance(accounts[0]);
        setBalance(web3.utils.fromWei(balance, 'ether'));
      } catch (error) {
        console.error("Connection to MetaMask failed", error);
      }
    } else {
      console.log('Please install MetaMask!');
    }
  }; 

    // Disconnect wallet
    const disconnectWalletHandler = () => {
        if (window.confirm("Are you sure you want to disconnect your wallet?")) {
          setWeb3(null);
          setAccounts([]);
          setBalance('');
        }
      };
    

  useEffect(() => {
    if (web3 && accounts.length > 0) {
      const loadBalance = async () => {
        const balance = await web3.eth.getBalance(accounts[0]);
        setBalance(web3.utils.fromWei(balance, 'ether'));
      };
      loadBalance();
    }
  }, [web3, accounts]);

  return (
    <>
    <Flex as="nav" bg="gray.800" color="white" align="center" justify="space-between" padding="1rem">
      <Box>Basic Degen</Box>
      <Flex gap="20px">
        <Text cursor="pointer" onClick={onLearnOpen}>Learn</Text>
        <Text cursor="pointer" onClick={onAboutOpen}>About</Text>
        <a href="https://docs.google.com/document/d/e/2PACX-1vTNDlw3wp5rQdXiNqilVetxNfSh-f87iw1RHb0loW7zxBek81rx9y-reLZo0UGb5o8taLR9L0J75g71/pub" target="_blank" rel="noreferrer"> Basic White Paper </a>
        <Text cursor="pointer" onClick={onGeneratorOpen}>Meme Generator</Text>
      </Flex>
      {accounts.length === 0 ? (
        <Button colorScheme="teal" onClick={connectWalletHandler}>Connect</Button>
      ) : (
        <Button colorScheme="teal" onClick={disconnectWalletHandler}>
          {balance.slice(0, 10)} ETH {accounts[0].slice(0, 5) + '...' + accounts[0].slice(-4)}
        </Button>
      )}

      <Modal isOpen={isLearnOpen} onClose={onLearnClose} width="600px">
        <ModalOverlay />
        <ModalContent  width="600px">
          <ModalHeader>Learn</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

          <iframe
            style={{ width: "100%", height: "315px", maxWidth: "560px" }} 
            src="https://www.youtube.com/embed/videoseries?si=widHkt82m_VD2VEE&amp;list=PLWyvxuLPPymOSZtSaLuINuS-lKrodQDFw" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAboutOpen} onClose={onAboutClose}>
        <ModalOverlay />
        <ModalContent width="600px">
          <ModalHeader>About</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

Welcome to the Basic Degen Universe! Here lies the innovative world of the 'Basic Degen' NFT collection, exclusively on the Base blockchain.  Featuring 12,000 unique tokens, each crafted with simplicity and accessibility at the forefront—so basic, even a Degen can do it!

As a Basic Degen holder, you're not just owning digital art; you're gaining a passport to an exhilarating ecosystem. Each token offers more than aesthetic appeal; it's your entry into a realm of continuous learning, community engagement, and blockchain education. We're dedicated to demystifying blockchain technology, creating a fun and inviting space that breaks down complexities and fosters understanding.

Join us and benefit from strategic partnerships that open doors to cross-chain activities and exclusive rewards. Our community thrives on integration and creating synergies with external projects, ensuring that your journey with Basic Degen is packed with surprises, especially at mint out.

Be part of the movement where art meets innovation—join the Basic Degen collection and turn the complexity of blockchain into fun and educational experiences.
</ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEcosystemOpen} onClose={onEcosystemClose}>
        <ModalOverlay />
        <ModalContent width="600px">
          <ModalHeader>Ecosystem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>This is the Ecosystem modal content.</ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isGenerator} onClose={onGeneratorClose} width="600px">
        <ModalOverlay />
        <ModalContent  width="600px">
          <ModalHeader>Meme Generator</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

           BM fam! The Basic Degen Meme Generator will be available soon! 
           Check back later to see if you can make a meme!
          
          </ModalBody>
        </ModalContent>
      </Modal>

    </Flex>
    <ConnectWalletWarning isConnected={isConnected} isCorrectNetwork={isCorrectNetwork} />

    </>
  );
};

export default Navbar;
