import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

const ConnectWalletWarning = ({ isConnected, isCorrectNetwork }) => {
  if (isConnected && !isCorrectNetwork) {
    return (
      <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Connect to the Base Network
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Please connect your wallet to the Base network before proceeding to mint.
        </AlertDescription>
      </Alert>
    );
  }

  return null;
};

export default ConnectWalletWarning;
