import React, { useState, useEffect } from 'react';
import { Box, Text, Heading, CircularProgress } from '@chakra-ui/react';

function EthPrice({ amount }) {
    const [ethPrice, setEthPrice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [usdValue, setUsdValue] = useState(null);

    useEffect(() => {
        const fetchEthPrice = async () => {
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD');
                const data = await response.json();
                setEthPrice(data.ethereum.usd);
                setUsdValue(data.ethereum.usd * amount);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch ETH price:', error);
                setIsLoading(false);
            }
        };

        fetchEthPrice();
    }, [amount]); // Dependency on amount to re-calculate when amount changes

    return (
        <>
          {/*  <Heading mb={4}>Ethereum Price</Heading> */}
            {isLoading ? (
                <CircularProgress isIndeterminate color="green.300" />
            ) : (
                <Text fontSize="sm" marginLeft={2}>
                   {/* {usdValue ? `ETH ${amount} = $${usdValue.toFixed(2)}` : "Failed to load price"} */}
                   {usdValue ?  `$${usdValue.toFixed(2)}` : "Failed to load price"}
                </Text>
            )}
        </>
    );
}

export default EthPrice;

/**
 * 
 *         <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg="white" maxW="sm">
            <Heading mb={4}>Ethereum Price</Heading> *
          {isLoading ? (
            <CircularProgress isIndeterminate color="green.300" />
        ) : (
            <Text fontSize="sm">
                {usdValue ? `ETH ${amount} = $${usdValue.toFixed(2)}` : "Failed to load price"} 
               {usdValue ? `$${usdValue.toFixed(2)}` : "Failed to load price"}
            </Text>
        )}
    </Box>
 */